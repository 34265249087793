<script setup lang="ts">
import { ref } from 'vue'
import {
  Typography,
  DropdownContent,
  DimmingOverlay,
  vClickOutside
} from '@temperworks/components'

import { TemperIcon, Flag } from '@temperworks/icons'
import { DropdownItemsInterface, DropdownVariant, DimmingOverlayVariant, TypographyType } from '@temperworks/types'
interface Props {
  variant: DropdownVariant
  items: DropdownItemsInterface[]
  icon?: string
  iconPosition?: string
  flag?: string
  topLabel?: string
  placeholder: string
  extraPlaceholderContent?: string
  selected?: string
  selectedMore?: string
  displayControls?: boolean
  disabled?: boolean
  selectAllOption?:boolean
  placeholderSearch?: string
  multiSelect?: boolean
  error?: boolean
  withinBounds?: boolean
}
const open = ref<boolean>(false)
const checked = ref<boolean>(false)
const emit = defineEmits(['selected', 'multiSelected'])
const props = withDefaults(defineProps<Props>(), {
  displayControls: true,
  icon: undefined,
  iconPosition: undefined,
  flag: undefined,
  topLabel: undefined,
  selected: undefined,
  placeholderSearch: '',
  extraPlaceholderContent: undefined,
  selectedMore: undefined,
  multiSelect: false,
  error: false
})

function toggleDropdown() {
  open.value = !open.value
  checked.value = !checked.value
}

function hideDropdown() {
  open.value = false
}

function selectedItem(item) {
  emit('selected', item)
  resetValues()
}

function resetValues() {
  open.value = false
  checked.value = false
}

function multipleSelected(item) {
  emit('multiSelected', item)
}
</script>

<template>
  <div
    class="relative"
    v-click-outside="hideDropdown"
  >
    <Typography
      v-if="props.topLabel"
      :variant="TypographyType.bodySmallMedium"
      :content="props.topLabel"
      class="label"
    />
    <div
      class="dropdown"
      :class="
        [
          { 'selected': checked },
          { 'disabled': props.disabled },
          { 'error': props.error },
        ]
      "
      @click="toggleDropdown()"
    >
      <div
        v-if="props.icon || props.flag"
        class="icon"
      >
        <TemperIcon
          v-if="props.icon"
          :name="props.icon"
          :position="props.iconPosition"
          fill="grey400"
        />
        <Flag
          v-if="props.flag"
          :name="props.flag"
        />
      </div>
      <div class="name">
        <div
          v-if="props.selected"
          class="selected flex-placeholder"
        >
          <Typography
            :variant="TypographyType.body"
            :content="props.selected"
          />
          <Typography
            v-if="props.selectedMore"
            :variant="TypographyType.body"
            :content="props.selectedMore"
            class="lightgrey"
          />
        </div>
        <div
          v-else
          class="flex-placeholder"
        >
          <Typography
            :variant="TypographyType.body"
            :content="props.placeholder"
          />
          <Typography
            v-if="props.extraPlaceholderContent"
            :variant="TypographyType.body"
            :content="props.extraPlaceholderContent"
            class="lightgrey"
          />
        </div>
      </div>
      <div
        v-if="props.displayControls"
        class="input-controls"
      >
        <TemperIcon
          name="dropdownCaretsC"
          size="large"
          position="center"
          :color="props.disabled ? 'grey800' : 'dark'"
        />
      </div>
    </div>
    <DropdownContent
      v-if="open && (props.items.length > 0 || $slots['extra-content'])"
      id="dropdown-content"
      :variant="props.variant"
      :items="props.items"
      :open="open"
      :select-all-option="props.selectAllOption"
      :placeholder-search="props.placeholderSearch"
      :multi-select="props.multiSelect"
      :selected="props.selected"
      :within-bounds="props.withinBounds"
      @selected="selectedItem($event)"
      @multi-selected="multipleSelected($event)"
    >
      <template #extra-content>
        <div @click="resetValues">
          <slot name="extra-content" />
        </div>
      </template>
    </DropdownContent>
    <DimmingOverlay
      v-if="open"
      :variant="DimmingOverlayVariant.bright"
      @close="toggleDropdown()"
    />
  </div>
</template>

<style lang="scss" scoped>
@use './Dropdown.scss';
</style>
